import React from "react"

import PropTypes from "prop-types"

import styles from "../styles/footer.module.styl"

const Footer = ({
  logoTitle,
  dpa_link,
  tos_link,
  privacy_link,
  cookies_link,
  copyright,
}) => {
  const copyrights = copyright.replace("__date__", new Date().getFullYear())

  return (
    <div className={styles.footer}>
      <p className={styles.logoTitle}>{logoTitle}</p>
      <p className={styles.links}>
        <a href={tos_link} target="_blank" rel="noopener noreferrer">
          Terms of service
        </a>
        <span>, </span>
        <a href={privacy_link} target="_blank" rel="noopener noreferrer">
          Privacy policy
        </a>
        <span>, </span>
        <a href={cookies_link} target="_blank" rel="noopener noreferrer">
          Cookies
        </a>
        <span>, </span>
        <a href={dpa_link} target="_blank" rel="noopener noreferrer">
          DPA
        </a>
      </p>
      <p className={styles.copyright}>{copyrights}</p>
    </div>
  )
}

Footer.propTypes = {
  dpa_link: PropTypes.string.isRequired,
  cookies_link: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
  logoTitle: PropTypes.string.isRequired,
  privacy_link: PropTypes.string.isRequired,
  tos_link: PropTypes.string.isRequired,
}

export default Footer
