/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import { useStaticQuery, graphql } from "gatsby"

import Helmet from "react-helmet"
import "typeface-lato"
import "typeface-montserrat"

import favicon from "../assets/favicon.png"

import "../../node_modules/@ep/react-components/umd/ReactComponents.css"
import "../../node_modules/@ep/editor/umd/Editor.css"

import "../styles/global.styl"
import styles from "../styles/app.module.styl"
import Footer from "./Footer"

const Layout = ({ pageId, withGlossaryButton, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          cookies_link
          copyright
          dpa_link
          logoTitle
          privacy_link
          title
          tos_link
        }
      }
    }
  `)

  const {
    cookies_link,
    copyright,
    dpa_link,
    logoTitle,
    privacy_link,
    title,
    tos_link,
  } = data.site.siteMetadata

  return (
    <>
      <div className={styles.page}>
        <Helmet
          title={title}
          link={[
            { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
          ]}
        />
        <div className={styles.content}>
          {withGlossaryButton && (
            <a
              className="link-to-glossary"
              target="_blank"
              rel="noopener noreferrer"
              href={`/${pageId}/glossary`}
            >
              Glossary
            </a>
          )}
          {children}
        </div>
        <Footer
          cookies_link={cookies_link}
          copyright={copyright}
          dpa_link={dpa_link}
          logoTitle={logoTitle}
          privacy_link={privacy_link}
          tos_link={tos_link}
        />
      </div>
    </>
  )
}

Layout.propTypes = {
  withGlossaryButton: PropTypes.bool,
  children: PropTypes.node.isRequired,
  pageId: PropTypes.string,
}

Layout.defaultProps = {
  pageId: null,
  withGlossaryButton: false,
}

export default Layout
